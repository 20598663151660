import axios, { AxiosResponse } from "axios";
import { AxiosRequestConfig, AxiosError, Method, AxiosRequestHeaders } from "axios";
import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { env } from "@/service";

// eslint-disable-next-line
export function request(service = env, method: Method, uri: string, params?: unknown, payload?: unknown, headers?: Array<AxiosRequestHeaders>) {
  const config: AxiosRequestConfig = {
    method: method,
    url: service + uri,
    params: params,
    data: payload,
    headers: {},
  };

  headers?.forEach((header) => {
    Object.assign(config.headers, header);
  });

  const response = axios(config);
  response
    .then((response: AxiosResponse) => {
      console.debug(0, response);
    })
    .catch((error: AxiosError) => {
      console.error(1, error);
      // Catch all general errors here
      if (error.response) {
        console.error(2, error.response);
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
            return showAlert("Ummm...", "Nějak k nám nedorazily všechny informace.", AlertSeverities.fatal, error.response.data.message);
          case 404:
            return showAlert("O.o", "Nenasel jsem", AlertSeverities.fatal, error.response.data.message);
          case 422:
            return showAlert("What?", "Co nám to sakra posíláš? To nedává smysl!", AlertSeverities.fatal, error.response.data.message);

          // Expired token

          case 500:
            return showAlert(
              "Spadl nám server :(",
              "Naše servery pravděpodobně nefungují a jsou dočasně nedostupné. Zkuste se k nám dostat za chvíli.",
              AlertSeverities.fatal,
              error.response.data.message,
              12000
            );
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(3, error.request);
        return showAlert("Uh-oh...", `Naše servery pravděpodobně nefungují jak mají :(. Už na tom pracujeme`, AlertSeverities.fatal, error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(4, error.message);
        return showAlert("Cože?!", `Tak tohle jsme nečekali. Cokoliv se právě stalo tak je divné...`, AlertSeverities.fatal, error.message);
      }
    });

  // Return data uniformly
  return response.then((response: AxiosResponse) => {
    return response.data;
  });
}
